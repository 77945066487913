export const pathKeys = {
  root: "/",
  artistsRoot: "artists",
  artworksRoot: "artworks",
  detailArtistRoot: "detail-artist",
  artworkRoot: "artwork",
  termsOfUseRoot: "terms-of-use",
  legalNoticeRoot: "legal-notice",
  privacyCookiesRoot: "privacy-cookies",
  home() {
    return pathKeys.root;
  },
  artists() {
    return pathKeys.artistsRoot;
  },
  artworks() {
    return pathKeys.artworksRoot;
  },
  detailArtist() {
    // TODO insérer paramètres id dynamiquement
    return pathKeys.detailArtistRoot;
  },
  artwork() {
    return pathKeys.artworkRoot;
  },
  termsOfUse() {
    return pathKeys.termsOfUseRoot;
  },
  legalNotice() {
    return pathKeys.legalNoticeRoot;
  },
  privacyCookies() {
    return pathKeys.privacyCookiesRoot;
  },
  page404() {
    return pathKeys.root.concat("404/");
  },
};
