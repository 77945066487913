export function TermsOfUsePage() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto">
      <article className="flex flex-col gap-6 w-full mx-auto px-8 py-10">
        <h2 className="font-bold text-2xl">Terms of Use</h2>
        <p>
          Sfumato is a website that provides information on the art market. The
          website uses an algorithm with AI assistance to process public data
          from art market marketplaces, including Artsy, Arnet, Saatchiart,
          Singulart, Sotheby&#x27;s, Drouot, Christie&#x27;s, and Artsper. The
          website also uses the Google Trends, Google Lens &amp; Pinterest APIs
          to enrich its database.
        </p>
        <div>
          <h3>
            <strong>Acceptance of Terms</strong>
          </h3>
          <p>
            By using Sfumato, you agree to be bound by these Terms of Service.
            If you do not agree to these Terms of Service, you may not use
            Sfumato.
          </p>
        </div>
        <div>
          <h3>
            <strong>Intellectual Property</strong>
          </h3>
          <p>
            Sfumato is owned and operated by [SFUMATO]. All content on Sfumato,
            including text, images, and code, is the property of [SFUMATO] or
            its licensors. You may not use any content on Sfumato without the
            express written permission of [SFUMATO].
          </p>
        </div>
        <div>
          <h3>
            <strong>Data Collection</strong>
          </h3>
          <p>
            Sfumato collects data from a variety of sources, including APIs
            &amp; Subscription
          </p>
        </div>
        <ul>
          <li>• Google APIs Explorer</li>
          <li>• ChatGPT4</li>
          <li>• Gemini Era</li>
          <li>• SerpApi</li>
          <li>• Statista</li>
          <li>• Artprice</li>
          <li>• Artfacts</li>
          <li>• Google Analytics 4</li>
          <li>• Google APIs Explorer</li>
          <li>• Pinterest</li>
          <li>• Goolgle Lens</li>
        </ul>
        <p>The data collected by Sfumato includes the following:</p>
        <ul>
          <li>• Title of the artwork.</li>
          <li>• Description of the artwork.</li>
          <li>• Characteristics of the artwork.</li>
          <li>• Price of the artwork.</li>
          <li>• Trends in the art market.</li>
          <li>• Sfumato uses data authorized by marketplaces as artsper.com</li>
          <li>
            • Sfumato does not sell or share the data collected with third
            parties.
          </li>
        </ul>
        <div>
          <h3>
            <strong>Privacy Policy</strong>
          </h3>
          <p>
            Sfumato&#x27;s privacy policy explains how Sfumato collects, uses,
            and shares your personal data. You can find Sfumato&#x27;s privacy
            policy at [privacy policy SFUMATO].
          </p>
        </div>
        <div>
          <h3>
            <strong>Disclaimer</strong>
          </h3>
          <p>
            Sfumato is provided on an &quot;as is&quot; basis. [SFUMATO] does
            not make any representations or warranties about the accuracy or
            completeness of the information on Sfumato.
          </p>
        </div>
        <div>
          <h3>
            <strong>Limitation of Liability</strong>
          </h3>
          <p>
            [SFUMATO] is not liable for any damages, including direct, indirect,
            incidental, consequential, or punitive damages, arising out of or in
            connection with your use of Sfumato.
          </p>
        </div>
        <div>
          <h3>
            <strong>Governing Law</strong>
          </h3>
          <p>
            [These Terms of Service are governed by the laws of the State of
            [FRANCE].
          </p>
        </div>
        <div>
          <h3>
            <strong>Changes to Terms of Service</strong>
          </h3>
          <p>
            [SFUMATO] may update these Terms of Service at any time. You should
            check the Terms of Service periodically for changes. Your continued
            use of Sfumato after any changes to the Terms of Service constitutes
            your acceptance of the changes.
          </p>
        </div>
        <div>
          <h3>
            <strong>Contact Information</strong>
          </h3>
          <p>
            If you have any questions about these Terms of Service, you can
            contact [SFUMATO] at [contact information].
          </p>
        </div>
        <div>
          <h3>
            <strong>Contact Information</strong>
          </h3>
          <p>
            The following additional terms apply to your use of Sfumato: -You
            must be at least 18 years old to use Sfumato. -You must not use
            Sfumato for any illegal or prohibited purpose. -You must not
            interfere with the operation of Sfumato. -You must not violate the
            intellectual property rights of [SFUMATO] or its licensors. -By
            using Sfumato, you agree to abide by these additional terms.
          </p>
        </div>
      </article>
    </div>
  );
}
