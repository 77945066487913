import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { ArtistsPage } from "./artist-page.ui";

export const artistsPageRoute: RouteObject = {
  path: pathKeys.artists(),
  element: createElement(ArtistsPage),
  loader: async (args) => {
    return args;
  },
};
