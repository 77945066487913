import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { SimilarArtworks } from "./similar-artworks.types";
import { SimilarArtworksQuery } from "./similar-artworks.api";

const keys = {
  root: () => ["similarArtworks"],
  similarArtworkById: (artwork_uuid: string) => [
    ...keys.root(),
    "byId",
    artwork_uuid,
  ],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const SimilarArtworksService = {
  queryKey: (artwork_uuid: string) => keys.similarArtworkById(artwork_uuid),

  getCache: (artwork_uuid: string) =>
    queryClient.getQueryData<SimilarArtworks>(
      SimilarArtworksService.queryKey(artwork_uuid),
    ),

  setCache: (similarArtworkById: SimilarArtworks, artwork_uuid: string) => {
    queryClient.setQueryData(
      SimilarArtworksService.queryKey(artwork_uuid),
      similarArtworkById,
    );
  },

  removeCache: (artwork_uuid: string) =>
    queryClient.removeQueries({
      queryKey: SimilarArtworksService.queryKey(artwork_uuid),
    }),

  queryOptions: (artwork_uuid: string) => {
    const chartKey = SimilarArtworksService.queryKey(artwork_uuid);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const similarArtworkById = await SimilarArtworksQuery(
          { artwork_uuid },
          signal,
        );
        SimilarArtworksService.setCache(similarArtworkById, artwork_uuid);

        return similarArtworkById;
      },
      initialData: () => SimilarArtworksService.getCache(artwork_uuid)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (artwork_uuid: string) =>
    queryClient.prefetchQuery(
      SimilarArtworksService.queryOptions(artwork_uuid),
    ),

  ensureQueryData: async (artwork_uuid: string) =>
    queryClient.ensureQueryData(
      SimilarArtworksService.queryOptions(artwork_uuid),
    ),
};
