import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { ReportsChartsTypes } from "shared/api/reports-charts";
import { CountryReportsQuery } from "./countries-reports.api";

const keys = {
  root: () => ["countryReports"],
  trendReports: (filter: string) => [...keys.root(), "byFilter", filter],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const CountryReportsService = {
  queryKey: (filter: string) => keys.trendReports(filter),

  getCache: (filter: string) =>
    queryClient.getQueryData<ReportsChartsTypes.ReportsChartsDto>(
      CountryReportsService.queryKey(filter),
    ),

  setCache: (
    trendReports: ReportsChartsTypes.ReportsChartsDto,
    filter: string,
  ) =>
    queryClient.setQueryData(
      CountryReportsService.queryKey(filter),
      trendReports,
    ),

  removeCache: (filter: string) =>
    queryClient.removeQueries({
      queryKey: CountryReportsService.queryKey(filter),
    }),

  queryOptions: (filter: string) => {
    const chartKey = CountryReportsService.queryKey(filter);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const trendReports = await CountryReportsQuery({ filter }, signal);
        CountryReportsService.setCache(trendReports, filter);

        return trendReports;
      },
      initialData: () => CountryReportsService.getCache(filter)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (filter: string) =>
    queryClient.prefetchQuery(CountryReportsService.queryOptions(filter)),

  ensureQueryData: async (filter: string) =>
    queryClient.ensureQueryData(CountryReportsService.queryOptions(filter)),
};
