import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { LegalNoticePage } from "./legal-notice.ui";

export const legalNoticePageRoute: RouteObject = {
  path: pathKeys.legalNotice(),
  element: createElement(LegalNoticePage),
  loader: async (args) => {
    return args;
  },
};
