import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { TermsOfUsePage } from "./term-of-use.page";

export const termsOfUsePageRoute: RouteObject = {
  path: pathKeys.termsOfUse(),
  element: createElement(TermsOfUsePage),
  loader: async (args) => {
    return args;
  },
};
