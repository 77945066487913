import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { ReportsChartsTypes } from "shared/api/reports-charts";
import { ArtistsReportsQuery } from "./artists-reports.api";

const keys = {
  root: () => ["artistsReports"],
  artistsReports: (filter: string) => [...keys.root(), "byFilter", filter],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const ArtistsReportsService = {
  queryKey: (filter: string) => keys.artistsReports(filter),

  getCache: (filter: string) =>
    queryClient.getQueryData<ReportsChartsTypes.ReportsChartsDto>(
      ArtistsReportsService.queryKey(filter),
    ),

  setCache: (
    artistsReports: ReportsChartsTypes.ReportsChartsDto,
    filter: string,
  ) =>
    queryClient.setQueryData(
      ArtistsReportsService.queryKey(filter),
      artistsReports,
    ),

  removeCache: (filter: string) =>
    queryClient.removeQueries({
      queryKey: ArtistsReportsService.queryKey(filter),
    }),

  queryOptions: (filter: string) => {
    const chartKey = ArtistsReportsService.queryKey(filter);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const artistsReports = await ArtistsReportsQuery({ filter }, signal);
        ArtistsReportsService.setCache(artistsReports, filter);

        return artistsReports;
      },
      initialData: () => ArtistsReportsService.getCache(filter)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (filter: string) =>
    queryClient.prefetchQuery(ArtistsReportsService.queryOptions(filter)),

  ensureQueryData: async (filter: string) =>
    queryClient.ensureQueryData(ArtistsReportsService.queryOptions(filter)),
};
