import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { DetailArtistTypes } from ".";
import { DetailArtistQuery } from "./detail-artist.api";

const keys = {
  root: () => ["detailArtist"],
  detailArtistsById: (artist_uuid: string) => [
    ...keys.root(),
    "byId",
    artist_uuid,
  ],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const DetailArtistService = {
  queryKey: (artist_uuid: string) => keys.detailArtistsById(artist_uuid),

  getCache: (artist_uuid: string) =>
    queryClient.getQueryData<DetailArtistTypes.DetailArtistDto>(
      DetailArtistService.queryKey(artist_uuid),
    ),

  setCache: (
    detailArtistsById: DetailArtistTypes.DetailArtistDto,
    artist_uuid: string,
  ) => {
    queryClient.setQueryData(
      DetailArtistService.queryKey(artist_uuid),
      detailArtistsById,
    );
  },

  removeCache: (artist_uuid: string) =>
    queryClient.removeQueries({
      queryKey: DetailArtistService.queryKey(artist_uuid),
    }),

  queryOptions: (artist_uuid: string) => {
    const chartKey = DetailArtistService.queryKey(artist_uuid);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const detailArtistsById = await DetailArtistQuery(
          { artist_uuid },
          signal,
        );
        DetailArtistService.setCache(detailArtistsById, artist_uuid);

        return detailArtistsById;
      },
      initialData: () => DetailArtistService.getCache(artist_uuid)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (artist_uuid: string) =>
    queryClient.prefetchQuery(DetailArtistService.queryOptions(artist_uuid)),

  ensureQueryData: async (artist_uuid: string) =>
    queryClient.ensureQueryData(DetailArtistService.queryOptions(artist_uuid)),
};
