import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { SalesArtist } from "./sales-artist.types";
import { GetSalesDetailArtist } from "./sales-artist.api";

const keys = {
  root: () => ["salesArtists"],
  salesDetailArtist: (artist_uuid: string) => [
    ...keys.root(),
    "byId",
    artist_uuid,
  ],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const SalesDetailArtistQuerie = {
  queryKey: (artist_uuid: string) => keys.salesDetailArtist(artist_uuid),

  getCache: (artist_uuid: string) =>
    queryClient.getQueryData<SalesArtist>(
      SalesDetailArtistQuerie.queryKey(artist_uuid),
    ),

  setCache: (salesDetailArtist: SalesArtist, artist_uuid: string) => {
    queryClient.setQueryData(
      SalesDetailArtistQuerie.queryKey(artist_uuid),
      salesDetailArtist,
    );
  },

  removeCache: (artist_uuid: string) =>
    queryClient.removeQueries({
      queryKey: SalesDetailArtistQuerie.queryKey(artist_uuid),
    }),

  queryOptions: (artist_uuid: string) => {
    const chartKey = SalesDetailArtistQuerie.queryKey(artist_uuid);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const salesDetailArtist = await GetSalesDetailArtist(
          { artist_uuid },
          signal,
        );
        SalesDetailArtistQuerie.setCache(salesDetailArtist, artist_uuid);

        return salesDetailArtist;
      },
      initialData: () => SalesDetailArtistQuerie.getCache(artist_uuid)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (artist_uuid: string) =>
    queryClient.prefetchQuery(
      SalesDetailArtistQuerie.queryOptions(artist_uuid),
    ),

  ensureQueryData: async (artist_uuid: string) =>
    queryClient.ensureQueryData(
      SalesDetailArtistQuerie.queryOptions(artist_uuid),
    ),
};
