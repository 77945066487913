import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { ArtworksPage } from "./artworks-page.ui";

export const artworksPageRoute: RouteObject = {
  path: pathKeys.artworks(),
  element: createElement(ArtworksPage),
  loader: async (args) => {
    return args;
  },
};
