import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { TrendReportsService } from "entities/trend-reports";
import { ReportsChartsTypes } from "shared/api/reports-charts";
import { LabelCLiqued } from "shared/lib/labels-cliqued";
import { Loader } from "shared/ui/loader";

export function AnalysticCardTrend({
  handleCLickFilter,
}: {
  handleCLickFilter: ({
    labelParamsFilter,
    filter,
  }: {
    labelParamsFilter: string;
    filter: string;
  }) => void;
}) {
  const [tabLabels, setTabLabels] = useState([] as LabelCLiqued[]);
  const [dataChart, setDataChart] = useState(
    {} as ReportsChartsTypes.ReportsChartsDto,
  );
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search),
  );
  useEffect(() => {
    setParams(new URLSearchParams(window.location.search));
  }, [tabLabels]);

  const { queryKey, ...options } =
    TrendReportsService.TrendsReportsService.queryOptions(
      params.size !== 0 ? (params.get("trendFilter") as string) : "Global",
    );

  const { data: queryResult, isLoading } = useQuery({ queryKey, ...options });

  useEffect(() => {
    !isLoading &&
      setDataChart({
        data: queryResult.data?.sort((a, b) => b.value - a.value),
        labels: queryResult?.labels,
        divisor: queryResult.divisor,
      });
  }, [queryResult, tabLabels, isLoading]);

  return (
    <div className="flex flex-col h-full col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-lg border border-slate-200 dark:border-slate-700">
      <header className="px-5 pt-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          Trend reports
        </h2>
      </header>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center m-auto h-full">
          <Loader />
          <p>Loading data</p>
        </div>
      ) : (
        <div className="grow p-3">
          <div className="flex flex-col gap-3 h-full">
            <div className="h-max">
              <ul className="flex flex-wrap min-h-full text-xs uppercase text-[#57534E] dark:text-white font-semibold">
                {dataChart != null && dataChart.labels != null ? (
                  dataChart.labels.map((label, index) => (
                    <li
                      className={`border-[#E7E5E4] border-2 px-2 shadow-md mr-1 mb-1 cursor-pointer ${
                        params.get("trendFilter") === label
                          ? `bg-red-100 dark:bg-red-400/30`
                          : ""
                      }`}
                      onClick={() => {
                        setTabLabels([]);
                        setTabLabels([{ label, index }]);
                        if (tabLabels.find((item) => item.label === label)) {
                          setTabLabels([]);
                        }
                        handleCLickFilter({
                          labelParamsFilter: "trendFilter",
                          filter: label,
                        });
                      }}
                      key={index}
                    >
                      {label}
                    </li>
                  ))
                ) : (
                  <li>Aucun tag n'a pu être chargé</li>
                )}
              </ul>
            </div>
            <div className="grow">
              <ul className="flex justify-between text-xs uppercase text-slate-400 dark:text-slate-500 font-semibold px-2 space-x-2">
                <li>Ranking</li>
                <li>Average Price</li>
              </ul>

              <ul className="space-y-1 text-sm text-slate-800 dark:text-slate-100 mt-3 mb-4">
                {dataChart != null && dataChart.data != null ? (
                  dataChart.data.map((item, index) => (
                    <li className={`relative px-2 py-1`} key={index}>
                      <div
                        className={`absolute inset-0 bg-red-100 dark:bg-red-400/30 `}
                        aria-hidden="true"
                        style={{ width: `${item.value / 100}%` }}
                      ></div>
                      <div className="relative flex justify-between space-x-2">
                        <div className="flex flex-row gap-2">
                          <p>{item.country}</p>
                          {item.name !== "" ? <p>-</p> : ""}
                          <p>{item.name !== "" ? item.name : ""}</p>
                        </div>

                        <div>${item.value}</div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li>
                    Aucune donnée n'a été chargée. Rechargez votre page ou
                    contactez un administrateur
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
