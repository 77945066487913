import { z } from "zod";

export const dataSimilarArtworkDto = z.object({
  id: z.string(),
  name: z.string(),
  nameArtist: z.string(),
  category: z.string(),
  price: z.number(),
  imgURL: z.string(),
  position: z.number(),
});

export const similarArtworksDto = z.object({
  artworkId: z.string(),
  totalArtworksCount: z.number(),
  data: z.array(dataSimilarArtworkDto),
});
