import {
  RouterProvider,
  createBrowserRouter,
  redirect,
  useRouteError,
} from "react-router-dom";
import { GenericLayout } from "pages/layouts";
import { homePageRoute } from "pages/home";
import { artistsPageRoute } from "pages/artists";
import { artworksPageRoute } from "pages/artworks";
import { artworkPageRoute } from "pages/artwork";
import { detailArtistPageRoute } from "pages/details-artist";
import { termsOfUsePageRoute } from "pages/terms-of-use";
import { legalNoticePageRoute } from "pages/legal-notice";
import { privacyCookiesRoute } from "pages/privacy-cookies";
import { page404Route } from "pages/page-404";
import { pathKeys } from "shared/lib/react-router";

function BubbleError() {
  const error = useRouteError();
  if (error) throw error;
  return null;
}

const router = createBrowserRouter([
  {
    errorElement: <BubbleError />,
    children: [
      {
        element: <GenericLayout />,
        children: [
          homePageRoute,
          artistsPageRoute,
          artworksPageRoute,
          artworkPageRoute,
          detailArtistPageRoute,
          termsOfUsePageRoute,
          legalNoticePageRoute,
          privacyCookiesRoute,
          page404Route,
        ],
      },
      {
        loader: async () => redirect(pathKeys.page404()),
        path: "*",
      },
    ],
  },
]);

export function BrowserRouter() {
  return <RouterProvider router={router} />;
}
