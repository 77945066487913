import { DataTableSalesArtist } from "widgets/data-table-sales-artist";
import { FintechCard } from "widgets/fintech-card";
import { DetailArtistQueries } from "entities/detail-artist";
import { useQuery } from "@tanstack/react-query";
import { DetailArtistTypes } from "entities/detail-artist";
import { useState, useEffect } from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Loader } from "shared/ui/loader";

export function DetailArtist() {
  const params = new URLSearchParams(window.location.search);
  const artist_uuid = params.get("artist_uuid") as string;
  const [artist, setArtist] =
    useState<DetailArtistTypes.DetailArtistDto | null>(null);

  const { queryKey, ...options } =
    DetailArtistQueries.DetailArtistService.queryOptions(artist_uuid);
  const { data: queryResult, isLoading } = useQuery({ queryKey, ...options });

  useEffect(() => {
    setArtist(queryResult);
  }, [artist_uuid, queryResult]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto lg:max-h-[100dvh] lg:h-[100dvh]">
      <div className="grid grid-cols-12 lg:grid-row-8 gap-6 lg:h-full lg:max-h-full">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center m-auto h-full bg-white w-full col-span-12 sm:col-span-12 sm:row-span-4 md:col-start-1 md:col-end-9 md:row-start-1 md:row-end-4 rounded-lg">
            <Loader />
            <p>Loading data</p>
          </div>
        ) : (
          <div className="max-h-full flex flex-col gap-6 py-12 px-8 col-span-12 sm:row-span-4 lg:col-start-1 lg:col-end-9 lg:row-start-1 lg:row-end-4 bg-white dark:bg-slate-800 rounded-lg lg:relative">
            <div className="flex flex-row items-center gap-6 static">
              {artist?.pictureUrl ? (
                <img
                  src={artist?.pictureUrl}
                  className="w-24 h-24 rounded-[3rem]"
                  alt="actual artist"
                />
              ) : (
                <div className="w-24 h-24 rounded-[3rem] bg-gray-300 dark:bg-gray-700"></div>
              )}

              <div className="flex flex-col gap-2">
                <h2 className="text-2xl font-bold">
                  {artist?.name ?? "Nom artiste"}
                </h2>
                <div className="flex flex-row gap-4 flex-wrap">
                  <div className="flex flex-row gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-[#A8A29E] dark:text-[#FFFFFF]"
                    >
                      <path
                        d="M17.6569 16.6569C16.7202 17.5935 14.7616 19.5521 13.4138 20.8999C12.6327 21.681 11.3677 21.6814 10.5866 20.9003C9.26234 19.576 7.34159 17.6553 6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315C20.781 8.46734 20.781 13.5327 17.6569 16.6569Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>{artist?.location ?? "Aucune location renseignée"}</p>
                    <p>
                      {artist?.country_iso_code
                        ? getUnicodeFlagIcon(artist?.country_iso_code as string)
                        : ""}
                    </p>
                  </div>
                  <div className="flex flex-row gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-[#A8A29E] dark:text-[#FFFFFF]"
                    >
                      <path
                        d="M13.8284 10.1716C12.2663 8.60948 9.73367 8.60948 8.17157 10.1716L4.17157 14.1716C2.60948 15.7337 2.60948 18.2663 4.17157 19.8284C5.73367 21.3905 8.26633 21.3905 9.82843 19.8284L10.93 18.7269M10.1716 13.8284C11.7337 15.3905 14.2663 15.3905 15.8284 13.8284L19.8284 9.82843C21.3905 8.26633 21.3905 5.73367 19.8284 4.17157C18.2663 2.60948 15.7337 2.60948 14.1716 4.17157L13.072 5.27118"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <p>{artist?.website ?? "Aucun site web renseigné"}</p>
                  </div>

                  <div className="flex flex-row gap-2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-[#A8A29E] dark:text-[#FFFFFF]"
                    >
                      <path
                        d="M9 19V13C9 11.8954 8.10457 11 7 11H5C3.89543 11 3 11.8954 3 13V19C3 20.1046 3.89543 21 5 21H7C8.10457 21 9 20.1046 9 19ZM9 19V9C9 7.89543 9.89543 7 11 7H13C14.1046 7 15 7.89543 15 9V19M9 19C9 20.1046 9.89543 21 11 21H13C14.1046 21 15 20.1046 15 19M15 19V5C15 3.89543 15.8954 3 17 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H17C15.8954 21 15 20.1046 15 19Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>{artist?.rankArtist ?? "Rang artiste"}</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="overflow-y-scroll max-h-56 break-words">
              {artist?.biography ?? "Aucune description renseignée"}
            </p>
          </div>
        )}
        <div className="flex flex-col col-span-12 row-span-2 lg:col-start-9 lg:col-end-13 lg:row-start-1 lg:row-end-5 rounded-lg">
          <FintechCard />
        </div>
        <div className="flex flex-col max-h-full overflow-hidden col-span-12 row-span-2 lg:col-start-1 lg:col-end-9 lg:row-start-4 lg:row-end-9 rounded-lg">
          <DataTableSalesArtist artist_uuid={artist_uuid} />
        </div>
        <div className="flex flex-col col-span-12 row-span-2 lg:col-start-9 lg:col-end-13 lg:row-start-5 lg:row-end-9 rounded-lg">
          <FintechCard />
        </div>
      </div>
    </div>
  );
}
