import { z } from "zod";

export const detailArtistOfArtworkDto = z.object({
  id: z.string(),
  name: z.string(),
  rankArtist: z.number(),
});

export const detailArtworkDto = z.object({
  id: z.string(),
  name: z.string(),
  artist: detailArtistOfArtworkDto,
  price: z.number(),
  technique: z.string(),
  dimensionsInch: z.string(),
  dimensionsMetric: z.string(),
  medium: z.string(),
  provenance: z.string(),
  onlineReputation: z.number(),
  advice: z.enum(["h_recommended", "recommended", "hold"]),
  image_url: z.string(),
});
