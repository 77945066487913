import { Outlet } from "react-router-dom";
import { useState } from "react";
import { Sidebar } from "shared/ui/sidebar";
import { Header } from "shared/ui/header";
import { TeasingModal } from "widgets/teasing-modal";

export function GenericLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isTeasingModalOpen, setIsTeasingModalOpen] = useState(false);

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={() => {
          setSidebarOpen(!sidebarOpen);
        }}
        handleClickTeasingModal={() => {
          setIsTeasingModalOpen(!isTeasingModalOpen);
        }}
      />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        />
        <main className="grow bg-[#F5F5F4]">
          <div className="sm:flex sm:justify-between sm:items-center">
            {isTeasingModalOpen && (
              <TeasingModal
                handleCloseModal={() =>
                  setIsTeasingModalOpen(!isTeasingModalOpen)
                }
              />
            )}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
