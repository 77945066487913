import { z } from "zod";

export const detailArtistDto = z.object({
  id: z.string(),
  pictureUrl: z.string(),
  name: z.string(),
  biography: z.string(),
  location: z.string(),
  country_iso_code: z.string().nullable().optional(),
  website: z.string().nullable(),
  rankArtist: z.number(),
});
