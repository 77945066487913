import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { ReportsChartsTypes } from "shared/api/reports-charts";
import { TrendReportsQuery } from "./trend-reports.api";

const keys = {
  root: () => ["trendReports"],
  trendReports: (filter: string) => [...keys.root(), "byFilter", filter],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const TrendsReportsService = {
  queryKey: (filter: string) => keys.trendReports(filter),

  getCache: (filter: string) =>
    queryClient.getQueryData<ReportsChartsTypes.ReportsChartsDto>(
      TrendsReportsService.queryKey(filter),
    ),

  setCache: (
    trendReports: ReportsChartsTypes.ReportsChartsDto,
    filter: string,
  ) =>
    queryClient.setQueryData(
      TrendsReportsService.queryKey(filter),
      trendReports,
    ),

  removeCache: (filter: string) =>
    queryClient.removeQueries({
      queryKey: TrendsReportsService.queryKey(filter),
    }),

  queryOptions: (filter: string) => {
    const chartKey = TrendsReportsService.queryKey(filter);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const trendReports = await TrendReportsQuery({ filter }, signal);
        TrendsReportsService.setCache(trendReports, filter);

        return trendReports;
      },
      initialData: () => TrendsReportsService.getCache(filter)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (filter: string) =>
    queryClient.prefetchQuery(TrendsReportsService.queryOptions(filter)),

  ensureQueryData: async (filter: string) =>
    queryClient.ensureQueryData(TrendsReportsService.queryOptions(filter)),
};
