import { zodContract } from "shared/lib/zod";
import { createJsonQuery } from "shared/lib/fetch";
import { baseUrl } from "shared/api/realworld";
import { salesArtistDto } from "./sales-artist.contracts";

export async function GetSalesDetailArtist(
  params: { artist_uuid: string },
  signal?: AbortSignal,
) {
  return createJsonQuery({
    request: {
      url: baseUrl(
        `${process.env.REACT_APP_API_URL_VERSION}/sfumato/artist_artwork_sales/`,
      ),
      method: "GET",
      query: {
        artist_uuid: params.artist_uuid,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    },
    response: {
      contract: zodContract(salesArtistDto),
    },
    abort: signal,
  });
}
