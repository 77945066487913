import { DataTableSimilarArtwork } from "widgets/data-table-similar-artworks";
import {
  DetailArtworkQueries,
  DetailArtworkTypes,
} from "entities/detail-artwork";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { Loader } from "shared/ui/loader";
import { ChartDetailArtwork } from "widgets/chart-detail-artwork";
import { Link } from "react-router-dom";

export function ArtworkPage() {
  const params = new URLSearchParams(window.location.search);
  const artwork_uuid = params.get("artwork_uuid") as string;
  const [artwork, setArtwork] =
    useState<DetailArtworkTypes.DetailArtwork | null>(null);

  const { queryKey: queryKeyArtwork, ...optionsSales } =
    DetailArtworkQueries.DetailArtworkService.queryOptions(artwork_uuid);
  const { data: queryArtwork, isLoading } = useQuery({
    queryKey: queryKeyArtwork,
    ...optionsSales,
  });

  useEffect(() => {
    setArtwork(queryArtwork);
  }, [artwork_uuid, queryArtwork]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto lg:max-h-[100dvh] lg:h-[100dvh]">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden ">
        <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto max-h-full h-full">
          <div className="grid h-full grid-cols-1 gap-6 lg:grid-cols-12 lg:grid-rows-12 max-h-full">
            <div className="flex flex-col bg-white lg:flex-row gap-4 col-span-1 sm:col-span-12 lg:col-start-1 lg:col-end-9 lg:row-start-1 lg:row-end-6 rounded-lg overflow-hidden">
              {isLoading ? (
                <div className="flex flex-col items-center justify-center m-auto h-full">
                  <Loader />
                  <p>Loading data</p>
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row h-full">
                  <div className="w-full lg:w-2/6">
                    <img
                      className="object-cover w-full h-full rounded-lg"
                      src={artwork?.image_url}
                      alt="artwork"
                    />
                  </div>
                  <div className="w-full h-full overflow-y-scroll lg:w-4/6 flex flex-col py-6 px-6 gap-2">
                    <div className="flex flex-col font-bold">
                      <div className="flex flex-row justify-between">
                        <h3 className="text-[2rem]">{artwork?.name}</h3>
                        <p className="text-[24px]">${artwork?.price}</p>
                      </div>
                      <Link
                        to={`/detail-artist?artist_uuid=${artwork?.artist.id}`}
                        className="text-[20px]"
                      >
                        {artwork?.artist.name}
                      </Link>
                    </div>
                    <div className="flex flex-row gap-2">
                      <p className="font-semibold	">Rank artist:</p>
                      <p>{artwork?.artist.rankArtist}</p>
                    </div>
                    <p>{artwork?.technique}</p>
                    <p>
                      {artwork?.dimensionsInch} in | {artwork?.dimensionsMetric}{" "}
                      cm
                    </p>
                    <hr className="border-[#E7E5E4]" />
                    <div className="flex flex-row flex-wrap gap-2 text-[#78716C]">
                      {/* <div className="flex flex-row gap-2 after:content-['⸱']">
												<p className="font-semibold">Rarity</p>
												<p>Rareté</p>
											</div> */}
                      <div className="flex flex-row gap-2 after:content-['⸱']">
                        <p className="font-semibold">Medium</p>
                        <p>{artwork?.medium}</p>
                      </div>
                      {/* <div className="flex flex-row gap-2 after:content-['⸱']">
												<p className="font-semibold">Condition</p>
												<p>Condition</p>
											</div> */}
                      {/* <div className="flex flex-row gap-2 after:content-['⸱']">
												<p className="font-semibold">Frame</p>
												<p>Frame</p>
											</div> */}
                      <div className="flex flex-row gap-2 after:content-['⸱']">
                        <p className="font-semibold">Provenance</p>
                        <p>{artwork?.provenance}</p>
                      </div>
                      <div className="flex flex-row gap-2 after:content-['⸱']">
                        <p className="font-semibold">Reputation</p>
                        <p>{artwork?.onlineReputation} followers</p>
                      </div>
                    </div>
                    <hr className="border-[#E7E5E4]" />
                    <div
                      className={`flex flex-row items-center ${artwork?.advice === "h_recommended" ? "text-[#16A34A]" : artwork?.advice === "recommended" ? "text-[#2563EB]" : "text-[#DC2626]"}  gap-2`}
                    >
                      <div className="flex flex-row flex-wrap items-end gap-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z"
                            fill="currentColor"
                          />
                        </svg>

                        <p className="font-semibold w-fit min-w-fit flex flex-row flex-nowrap">
                          Sfumato Expertise :
                        </p>
                        <p className="text-xs">
                          {artwork?.advice === "h_recommended"
                            ? "Highly recommended due to exceptional performance."
                            : artwork?.advice === "recommended"
                              ? "Recommended, showing solid performance."
                              : "Not recommended at this time due to underperformance."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col bg-white h-full col-span-1 sm:col-span-12 sm:row-span-2 lg:col-start-9 lg:col-end-13 lg:row-start-1 lg:row-end-5 rounded-lg">
              <ChartDetailArtwork />
            </div>
            <div className="flex flex-col bg-white h-full col-span-1 sm:col-span-12 sm:row-span-2 lg:col-start-9 lg:col-end-13 lg:row-start-5 lg:row-end-9 rounded-lg">
              <ChartDetailArtwork />
            </div>
            <div className="flex flex-col bg-white h-full col-span-1 sm:col-span-12 sm:row-span-2 lg:col-start-9 lg:col-end-13 lg:row-start-9 lg:row-end-13 rounded-lg">
              <ChartDetailArtwork />
            </div>
            <div className="flex flex-col col-span-1 sm:col-span-12 sm:row-span-8 lg:col-start-1 lg:col-end-9 lg:row-start-6 lg:row-end-13 rounded-lg overflow-hidden">
              <DataTableSimilarArtwork />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
