import { useRef, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SfumatoLogo } from "shared/ui/sfumato-logo";

export function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  handleClickTeasingModal,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  handleClickTeasingModal: () => void;
}) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      if (sidebarExpanded) {
        bodyElement.classList.add("sidebar-expanded");
      } else {
        bodyElement.classList.remove("sidebar-expanded");
      }
    }
  }, [sidebarExpanded]);

  return (
    <div className="min-w-fit">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed bg-greyscale-800 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 md:static md:left-auto md:top-auto md:translate-x-0 h-[100dvh] overflow-y-scroll md:overflow-y-auto no-scrollbar w-64 md:w-20 md:sidebar-expanded:!w-64 shrink-0 bg-greyscale-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3">
          {/* Close button */}
          <button
            ref={trigger}
            className=" h-12 md:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current "
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <div
            className={`h-12 hidden md:block ${sidebarExpanded ? "w-2/3" : "w-36"}`}
          >
            <NavLink end to="/" className={"block"}>
              <SfumatoLogo textColor="#FFFFFF" circleColor="#000000" />
            </NavLink>
          </div>
        </div>

        {/* Links */}
        <div className="space-y-8">
          <div className="flex flex-col gap-6">
            <div>
              <h3 className="text-xs uppercase text-primary-100 font-semibold pl-3">
                <span
                  className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                  aria-hidden="true"
                >
                  •••
                </span>
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  Pages
                </span>
              </h3>
              <ul className="mt-3">
                {/* Dashboard */}
                <li
                  className={`px-3 py-2 rounded-lg mb-0.5 last:mb-0 ${pathname === "/" && "bg-greyscale-900"} && "bg-greyscale-900"}`}
                >
                  <NavLink
                    end
                    to="/"
                    className={`text-slate-200 truncate flex flex-row items-center transition duration-150 ${
                      pathname === "/"
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                    onClick={(e) => {
                      setSidebarOpen(false);
                    }}
                  >
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current ${pathname === "/" ? "text-primary-100" : "text-white"}`}
                        d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L4 10.4142V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17V10.4142L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Dashboard
                    </span>
                  </NavLink>
                </li>
                {/* Artists */}
                <li
                  className={`px-3 py-2 rounded-lg mb-0.5 last:mb-0 ${pathname === "/artists" && "bg-greyscale-900"} && "bg-greyscale-900"}`}
                >
                  <NavLink
                    end
                    to="/artists"
                    className={`flex flex-row items-center text-slate-200 truncate transition duration-150 ${
                      pathname === "/artists"
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                    onClick={(e) => {
                      setSidebarOpen(false);
                    }}
                  >
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current ${pathname === "/artists" ? "text-primary-100" : "text-white"}`}
                        d="M9.04894 3.92707C9.3483 3.00576 10.6517 3.00576 10.9511 3.92707L12.0206 7.21886C12.1545 7.63089 12.5385 7.90985 12.9717 7.90985H16.4329C17.4016 7.90985 17.8044 9.14946 17.0207 9.71886L14.2205 11.7533C13.87 12.0079 13.7234 12.4593 13.8572 12.8713L14.9268 16.1631C15.2261 17.0844 14.1717 17.8506 13.388 17.2812L10.5878 15.2467C10.2373 14.9921 9.7627 14.9921 9.41221 15.2467L6.61204 17.2812C5.82833 17.8506 4.77385 17.0844 5.0732 16.1631L6.14277 12.8713C6.27665 12.4593 6.12999 12.0079 5.7795 11.7533L2.97933 9.71886C2.19562 9.14946 2.59839 7.90985 3.56712 7.90985H7.02832C7.46154 7.90985 7.8455 7.63089 7.97937 7.21886L9.04894 3.92707Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Artists
                    </span>
                  </NavLink>
                </li>
                {/* Artworks */}
                <li
                  className={`px-3 py-2 rounded-lg mb-0.5 last:mb-0 ${pathname === "/artworks" && "bg-greyscale-900"} && "bg-greyscale-900"}`}
                >
                  <NavLink
                    end
                    to="/artworks"
                    className={`flex flex-row items-center text-slate-200 truncate transition duration-150 ${
                      pathname === "/artworks"
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                    onClick={(e) => {
                      setSidebarOpen(false);
                    }}
                  >
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current ${pathname === "/artworks" ? "text-primary-100" : "text-white"}`}
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071L2.29289 10.7071C2.0976 10.5118 1.99997 10.2558 2 9.99988V5C2 3.34315 3.34315 2 5 2H10.0003C10.2561 2.00007 10.5119 2.0977 10.7071 2.29289L17.7071 9.29289ZM5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6Z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Artworks
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div
              className={`${sidebarExpanded ? "flex" : "hidden"}  rounded-lg text-xs text-white bg-greyscale-700 p-3 flex-col gap-4`}
            >
              <p>
                Access to Sfumato expertise based on the most up-to-date
                database with AI assistance.
              </p>
              <button
                onClick={() => {
                  handleClickTeasingModal();
                }}
                className="bg-primary-100 font-medium py-1 px-2 rounded-lg text-black"
              >
                Upgrade - $29/mo
              </button>
            </div>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 flex flex-col gap-2 mt-auto">
          <div>
            <h3 className="text-xs uppercase text-primary-100 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                More
              </span>
            </h3>
            <ul className="pt-2">
              <li
                className={`px-3 py-1 rounded-lg mb-0.5 last:mb-0 ${pathname.includes("terms-of-use") && "bg-greyscale-900"}  && "bg-greyscale-900"}`}
              >
                <NavLink
                  end
                  to="/terms-of-use"
                  className={`flex flex-row items-center text-slate-200 truncate transition duration-150 ${
                    pathname.includes("terms-of-use")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                  }`}
                  onClick={(e) => {
                    setSidebarOpen(false);
                  }}
                >
                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <path
                      d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
                      className={`fill-current ${pathname.includes("terms-of-use") ? "text-primary-100" : "text-white"}`}
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
                      className={`fill-current ${pathname.includes("terms-of-use") ? "text-primary-100" : "text-white"}`}
                    />
                  </svg>
                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    Terms of use
                  </span>
                </NavLink>
              </li>
              <li
                className={`px-3 py-1 rounded-lg mb-0.5 last:mb-0 ${pathname.includes("legal-notice") && "bg-greyscale-900"}  && "bg-greyscale-900"}`}
              >
                <NavLink
                  end
                  to="/legal-notice"
                  className={`flex flex-row items-center text-slate-200 truncate transition duration-150 ${
                    pathname.includes("legal-notice")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                  }`}
                  onClick={(e) => {
                    setSidebarOpen(false);
                  }}
                >
                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 2C10.5523 2 11 2.44772 11 3V4.32297L14.9544 5.90474L16.5528 5.10557C17.0468 4.85858 17.6474 5.05881 17.8944 5.55279C18.1414 6.04676 17.9412 6.64744 17.4472 6.89443L16.2141 7.51101L17.9522 12.9307C18.0728 13.3065 17.961 13.718 17.6669 13.9812C16.9599 14.614 16.0238 15 15 15C13.9762 15 13.0401 14.614 12.3331 13.9812C12.039 13.718 11.9273 13.3065 12.0478 12.9307L13.7631 7.58227L11 6.47703V16H13C13.5523 16 14 16.4477 14 17C14 17.5523 13.5523 18 13 18H7.00001C6.44772 18 6.00001 17.5523 6.00001 17C6.00001 16.4477 6.44772 16 7.00001 16H9.00001V6.47703L6.23692 7.58227L7.95223 12.9307C8.07276 13.3065 7.96099 13.718 7.66692 13.9812C6.95991 14.614 6.02384 15 5.00001 15C3.97617 15 3.0401 14.614 2.33309 13.9812C2.03902 13.718 1.92726 13.3065 2.04778 12.9307L3.78595 7.51101L2.55279 6.89443C2.05881 6.64744 1.85859 6.04676 2.10558 5.55279C2.35257 5.05881 2.95324 4.85858 3.44722 5.10557L5.04556 5.90474L9.00001 4.32297V3C9.00001 2.44772 9.44772 2 10 2ZM5.00001 10.2745L4.18177 12.8258C4.43135 12.9378 4.70804 13 5.00001 13C5.29198 13 5.56866 12.9378 5.81824 12.8258L5.00001 10.2745ZM15 10.2745L14.1818 12.8258C14.4314 12.9378 14.708 13 15 13C15.292 13 15.5687 12.9378 15.8182 12.8258L15 10.2745Z"
                      className={`fill-current ${pathname.includes("legal-notice") ? "text-primary-100" : "text-white"}`}
                    />
                  </svg>
                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    Legal notice
                  </span>
                </NavLink>
              </li>
              <li
                className={`px-3 py-1 rounded-lg mb-0.5 last:mb-0 ${pathname.includes("privacy-cookies") && "bg-greyscale-900"}  && "bg-greyscale-900"}`}
              >
                <NavLink
                  end
                  to="/privacy-cookies"
                  className={`flex flex-row items-center text-slate-200 truncate transition duration-150 ${
                    pathname.includes("privacy-cookies")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                  }`}
                  onClick={(e) => {
                    setSidebarOpen(false);
                  }}
                >
                  <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.16611 4.99891C5.17437 4.95809 7.91528 3.81033 10 1.94446C12.0847 3.81033 14.8256 4.95809 17.8339 4.99891C17.9431 5.64968 18 6.31821 18 7.00003C18 12.2249 14.6608 16.6698 10 18.3172C5.33923 16.6698 2 12.2249 2 7.00003C2 6.31821 2.05686 5.64968 2.16611 4.99891ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                      className={`fill-current ${pathname.includes("privacy-cookies") ? "text-primary-100" : "text-white"}`}
                    />
                  </svg>

                  <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    Privacy and cookies
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="hidden md:inline-flex px-4 md:justify-end">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className={`w-6 h-6 fill-current ${sidebarExpanded ? "rotate-180" : "rotate-0"}`}
                viewBox="0 0 24 24"
              >
                <path
                  className="text-white"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-white" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>  */}
        </div>
      </div>
    </div>
  );
}
