export function TeasingModal({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) {
  return (
    <div className="absolute z-30 top-0 bg-[#0C0A0999] w-full h-[100dvh] flex flex-col items-center justify-center gap-4 ">
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-lg border border-slate-200 dark:border-slate-700 px-4 py-8 flex flex-col items-center gap-2">
        <div>
          <div className="rounded-full bg-[#FEEAD8] w-20 px-4 py-4">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
                stroke="#111827"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1 max-w-96 text-center">
          <p className="font-semibold text-lg">Locked feature</p>
          <p className="text-[#78716C]">
            This feature is blocked for now. It will be available in the next
            version of the platform.
          </p>
        </div>
        <button
          onClick={() => {
            handleCloseModal();
          }}
          className="flex flex-row bg-[#272523] gap-2 text-white rounded-lg px-3 py-2"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 13L9 17L19 7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
          Got it!
        </button>
      </div>
    </div>
  );
}
