import { PaintingChart } from "widgets/painting-chart";
import { SculptureChart } from "widgets/sculpture-chart";
import { AnalysticCard } from "widgets/artists-reports";
import { AnalysticCardTrend } from "widgets/trend-reports";
import { AnalysticCardCountries } from "widgets/countries-reports";
import { useState } from "react";

export function HomePage() {
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search),
  );

  /**
   * Fonctions permettant de gérer les paramètres dans l'url et de les donner au mieux au composant de reports
   * @param labelParamsFilter: label du paramètre à modifier
   * @param filter: valeur du paramètre à modifier
   */
  const handleHomeClickFilter = ({
    labelParamsFilter,
    filter,
  }: {
    labelParamsFilter: string;
    filter: string;
  }) => {
    if (params.get(labelParamsFilter) === filter) {
      params.delete(labelParamsFilter);
    } else {
      params.set(labelParamsFilter, filter);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`,
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto">
      <div className="lg:flex justify-between items-center w-full">
        <h1 className="text-2xl md:text-3xl pr-4 text-slate-800 dark:text-slate-100 font-bold">
          Dashboard
        </h1>
        <div className="flex items-center text-[#475569]">
          <p className="font-light text-xs	">
            Sfumato the most updated database on the contemporary art market
            with 374,967 artists referenced.
          </p>
          <hr className="rotate-90 w-6" />
          <p>Global Market 2024</p>
        </div>
      </div>

      {/* Ajouter h-full pour faire en sorte que les graphs prennent toute la hauteur lors du loading  */}
      <div className="grid grid-cols-12 gap-6 h-full">
        <PaintingChart />
        <SculptureChart />
        <PaintingChart />
        <SculptureChart />
        <AnalysticCard handleCLickFilter={handleHomeClickFilter} />
        <AnalysticCardTrend handleCLickFilter={handleHomeClickFilter} />
        <AnalysticCardCountries handleCLickFilter={handleHomeClickFilter} />
      </div>
    </div>
  );
}
