import { TabArtists } from "shared/lib/mocks/temp.dataTableArtists.mock";

export function DataTableArtists({
  tabAllArtists,
}: {
  tabAllArtists: TabArtists;
}) {
  const handleRedirect = () => {
    window.location.href = "detail-artist/";
  };
  return (
    <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full dark:text-slate-300">
          {/* Table header */}
          <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
            <tr>
              <th className="p-2 py-4 pl-4 whitespace-nowrap">
                <div className="font-semibold text-left">Name</div>
              </th>
              <th className="p-2 py-4 whitespace-nowrap">
                <div className="font-semibold text-left">Website</div>
              </th>
              <th className="p-2 py-4 whitespace-nowrap">
                <div className="font-semibold text-left">Location</div>
              </th>
              <th className="p-2 py-4 whitespace-nowrap">
                <div className="font-semibold text-center">Position</div>
              </th>
              <th className="p-2 py-4 whitespace-nowrap">
                <div className="font-semibold text-center">Works Online</div>
              </th>
              <th className="p-2 py-4 pr-4 whitespace-nowrap">
                <div className="font-semibold text-center">Sales volume</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700">
            {/* Row */}
            {tabAllArtists.map((artist, index) => (
              <tr
                className="hover:cursor-pointer"
                onClick={() => handleRedirect()}
                key={index}
              >
                <td className="p-2 py-4 pl-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="shrink-0 rounded-full mr-2 sm:mr-3 bg-indigo-500">
                      <svg
                        className="w-9 h-9 fill-current text-indigo-50"
                        viewBox="0 0 36 36"
                      >
                        <path d="M24.446 19.335a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM20.431 11.938a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM14.969 20.25a2.49 2.49 0 00-1.932-1.234A4.624 4.624 0 0113 18.5a4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 0011 18.5c.003.391.04.781.11 1.166a2.5 2.5 0 103.859.584z" />
                      </svg>
                    </div>
                    <div className="font-medium text-slate-800 dark:text-slate-100">
                      {artist.name}
                    </div>
                  </div>
                </td>
                <td className="p-2 py-4 whitespace-nowrap">
                  <div className="flex shrink-0 -space-x-3 -ml-px">
                    {artist.website}
                  </div>
                </td>
                <td className="p-2 py-4 whitespace-nowrap">
                  <div className="flex items-center">{artist.location}</div>
                </td>
                <td className="p-2 py-4 whitespace-nowrap">
                  <div className="text-center">{artist.position}</div>
                </td>
                <td className="p-2 py-4 whitespace-nowrap">
                  <div className="text-lg text-center">
                    {artist.worksOnline}
                  </div>
                </td>
                <td className="p-2 py-4 pr-4 whitespace-nowrap">
                  <div className="text-center font-medium text-emerald-500">
                    {artist.salesVolume}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
