import { useQuery } from "@tanstack/react-query";
import {
  SalesDetailArtistQueries,
  SalesDetailArtistTypes,
} from "entities/sales-artist";
import { useEffect, useState } from "react";
import { Loader } from "shared/ui/loader";
import { Link } from "react-router-dom";

export function DataTableSalesArtist({ artist_uuid }: { artist_uuid: string }) {
  const [tabSales, setTabSales] =
    useState<SalesDetailArtistTypes.SalesArtist | null>(null);

  const { queryKey: queryKeySales, ...optionsSales } =
    SalesDetailArtistQueries.SalesDetailArtistQuerie.queryOptions(artist_uuid);
  const { data: querySales, isLoading } = useQuery({
    queryKey: queryKeySales,
    ...optionsSales,
  });

  useEffect(() => {
    setTabSales(querySales);
  }, [artist_uuid, querySales]);

  const handleRedirectArtworkPage = (idArtwork: string) => {
    window.location.href = `/artwork?artwork_uuid=${idArtwork}`;
  };

  return (
    <div className="col-span-full xl:col-span-8 bg-white dark:bg-slate-800 h-full max-h-full rounded-lg border border-slate-200 dark:border-slate-700 overflow-auto">
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader />
          <p>Loading data</p>
        </div>
      ) : (
        <div className="h-full">
          <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex flex-row justify-between sticky top-0 z-10 bg-white">
            <h2 className="font-semibold text-slate-800 dark:text-slate-100">
              Sales
            </h2>
            <div className="flex flex-row gap-2">
              <p>Sales Status Artist:</p>
              <p className="font-bold">${tabSales?.salesStatusArtist}</p>
            </div>
          </header>
          <div>
            <table className="table-auto w-full dark:text-slate-300">
              <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
                <tr>
                  <th className="p-2 pl-5">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">
                      Date On Sale
                    </div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Status</div>
                  </th>
                  <th className="p-2 pr-5">
                    <div className="font-semibold text-center">Price</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm font-medium divide-y divide-slate-100 dark:divide-slate-700 relative">
                {tabSales?.artworks.map((sale, index) => (
                  <tr
                    onClick={() => {
                      handleRedirectArtworkPage(sale.id);
                    }}
                    key={sale.id}
                    className="hover:cursor-pointer hover:bg-slate-50 h-fit relative"
                  >
                    <td className="p-2 pl-5">
                      <div className="flex flex-row items-center gap-2">
                        <img
                          src={sale.imgURL}
                          className="w-10 h-10 rounded-full"
                        />
                        <div className="text-slate-800 dark:text-slate-100">
                          {sale.name}
                        </div>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-center">{sale.dateOnSale}</div>
                    </td>
                    <td className="p-2">
                      <div className="text-right flex flex-row justify-center w-max">
                        <p
                          className={`rounded-2xl w-fit px-3 py-1 ${sale.status === "On Sale" ? " text-[#16A34A] bg-[#DCFCE7]" : "text-[#DC2626] bg-[#FEE2E2]"} `}
                        >
                          {sale.status}
                        </p>
                      </div>
                    </td>
                    <td className="p-2 pr-5">
                      <div className="text-center">${sale.price}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
