export function SfumatoLogo({
  textColor,
  circleColor,
}: {
  textColor: string;
  circleColor: string;
}) {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 140 35"
      enableBackground="new 0 0 140 35"
    >
      <circle fill={textColor} cx="70.188" cy="17.41" r="17.398" />
      <path
        opacity="0.2"
        fill={textColor}
        enableBackground="new    "
        d="M52.79,17.411c0-9.611,7.79-17.398,17.398-17.398
	c9.608,0,17.398,7.788,17.398,17.398"
      />
      <g id="Logo_1_">
        <g>
          <polygon
            fill={circleColor}
            points="72.82,11.266 72.82,12.097 67.554,12.097 67.554,11.266 65.338,11.266 65.338,22.605 
			67.554,22.605 67.554,14.31 69.077,14.31 69.077,23.711 71.296,23.711 71.296,14.31 72.82,14.31 72.82,22.605 75.038,22.605 
			75.038,11.266 		"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={textColor}
            d="M101.332,23.711l-0.25-1.727h-0.081c-0.547,0.703-1.113,1.2-1.692,1.491
			c-0.582,0.29-1.264,0.438-2.052,0.438c-1.075,0-1.906-0.273-2.505-0.823c-0.596-0.55-0.894-1.309-0.894-2.279
			c0-1.066,0.442-1.894,1.333-2.482c0.887-0.588,2.17-0.9,3.852-0.935l2.078-0.059V16.61c0-1.036-0.214-1.821-0.633-2.35
			c-0.422-0.529-1.103-0.793-2.039-0.793c-1.014,0-2.06,0.281-3.145,0.844l-0.37-0.865c1.197-0.563,2.384-0.843,3.555-0.843
			c1.198,0,2.095,0.312,2.688,0.936c0.589,0.621,0.891,1.595,0.891,2.913v7.266h-0.734L101.332,23.711L101.332,23.711z
			 M97.314,23.008c1.167,0,2.086-0.33,2.757-1c0.673-0.668,1.013-1.59,1.013-2.776v-1.074l-1.91,0.082
			c-1.533,0.075-2.624,0.312-3.277,0.717c-0.655,0.409-0.979,1.036-0.979,1.896c0,0.683,0.209,1.215,0.629,1.593
			C95.959,22.82,96.552,23.008,97.314,23.008z"
          />
          <path
            fill={textColor}
            d="M97.256,24.177c-1.135,0-2.036-0.301-2.682-0.894c-0.649-0.602-0.978-1.433-0.978-2.471
			c0-1.154,0.487-2.064,1.45-2.703c0.924-0.612,2.266-0.941,3.989-0.978l1.823-0.05v-0.471c0-0.969-0.195-1.705-0.576-2.188
			c-0.37-0.46-0.984-0.695-1.834-0.695c-0.966,0-1.981,0.274-3.025,0.814l-0.249,0.129l-0.582-1.354l0.228-0.107
			c1.225-0.578,2.462-0.869,3.669-0.869c1.269,0,2.235,0.341,2.876,1.017c0.639,0.671,0.963,1.712,0.963,3.092v7.527h-1.258v-0.265
			l-0.175-1.194c-0.476,0.54-0.969,0.937-1.471,1.19C98.803,24.018,98.074,24.177,97.256,24.177z M98.449,13.205
			c1.015,0,1.769,0.3,2.244,0.891c0.457,0.58,0.688,1.423,0.688,2.513v0.979l-2.331,0.067c-1.626,0.032-2.875,0.333-3.714,0.891
			c-0.819,0.543-1.219,1.282-1.219,2.264c0,0.898,0.268,1.582,0.812,2.087c0.551,0.507,1.312,0.756,2.326,0.756
			c0.735,0,1.388-0.141,1.936-0.412c0.544-0.271,1.082-0.749,1.604-1.418l0.077-0.101h0.437l0.25,1.729h0.247v-7.003
			c0-1.242-0.276-2.16-0.82-2.732c-0.537-0.566-1.376-0.853-2.497-0.853c-1.055,0-2.137,0.239-3.214,0.713l0.161,0.374
			C96.466,13.458,97.479,13.205,98.449,13.205z M97.314,23.271c-0.828,0-1.481-0.214-1.945-0.635
			c-0.473-0.426-0.715-1.025-0.715-1.783c0-0.949,0.371-1.662,1.102-2.118c0.697-0.43,1.81-0.677,3.406-0.757l2.182-0.092v1.347
			c0,1.249-0.367,2.247-1.089,2.964C99.54,22.907,98.549,23.271,97.314,23.271z M100.821,18.431l-1.637,0.066
			c-1.481,0.074-2.544,0.304-3.154,0.68c-0.574,0.359-0.852,0.907-0.852,1.674c0,0.612,0.177,1.07,0.542,1.396
			c0.365,0.33,0.9,0.501,1.594,0.501c1.107,0,1.947-0.303,2.573-0.926c0.627-0.622,0.934-1.471,0.934-2.591V18.431z"
          />
        </g>
        <g>
          <path
            fill={textColor}
            d="M111.775,23.029c0.631,0,1.179-0.054,1.647-0.157v0.8c-0.48,0.164-1.039,0.243-1.668,0.243
			c-0.968,0-1.679-0.256-2.139-0.77c-0.454-0.52-0.686-1.331-0.686-2.434V13.66h-1.611v-0.583l1.615-0.453l0.503-2.47h0.512v2.642
			h3.205v0.865h-3.205v6.913c0,0.834,0.15,1.455,0.442,1.854C110.681,22.828,111.148,23.029,111.775,23.029z"
          />
          <path
            fill={textColor}
            d="M111.756,24.177c-1.037,0-1.821-0.289-2.334-0.859c-0.501-0.567-0.753-1.445-0.753-2.606v-6.792h-1.614
			v-1.041l1.654-0.463l0.513-2.523h0.984v2.641h3.209v1.388h-3.209v6.651c0,0.772,0.135,1.348,0.394,1.702
			c0.24,0.329,0.634,0.494,1.176,0.494c0.606,0,1.145-0.053,1.591-0.15l0.318-0.071v1.315l-0.18,0.062
			C113.002,24.09,112.412,24.177,111.756,24.177z M107.578,13.396h1.616v7.312c0,1.033,0.208,1.793,0.622,2.26
			c0.41,0.458,1.047,0.684,1.939,0.684c0.522,0,0.995-0.059,1.406-0.173v-0.29c-0.411,0.064-0.878,0.102-1.389,0.102
			c-0.718,0-1.251-0.237-1.594-0.71c-0.327-0.445-0.492-1.123-0.492-2.011v-7.172h3.204v-0.342h-3.204v-2.642h-0.038l-0.49,2.418
			l-1.581,0.442V13.396z"
          />
        </g>
        <g>
          <path
            fill={textColor}
            d="M128.104,18.237c0,1.784-0.43,3.174-1.292,4.175c-0.866,1.002-2.056,1.503-3.575,1.503
			c-0.96,0-1.802-0.231-2.533-0.691c-0.73-0.464-1.29-1.128-1.678-1.99s-0.583-1.859-0.583-2.992c0-1.781,0.432-3.168,1.297-4.163
			c0.86-0.995,2.049-1.493,3.552-1.493s2.679,0.505,3.532,1.513C127.679,15.103,128.104,16.484,128.104,18.237z M119.488,18.237
			c0,1.501,0.328,2.673,0.986,3.512c0.662,0.842,1.591,1.259,2.799,1.259c1.206,0,2.138-0.417,2.796-1.259
			c0.662-0.841,0.99-2.013,0.99-3.512c0-1.505-0.33-2.674-1.002-3.505c-0.664-0.83-1.599-1.244-2.806-1.244
			c-1.206,0-2.134,0.415-2.788,1.242C119.814,15.554,119.488,16.724,119.488,18.237z"
          />
          <path
            fill={textColor}
            d="M123.237,24.177c-0.999,0-1.897-0.245-2.673-0.73c-0.771-0.494-1.369-1.2-1.777-2.104
			c-0.399-0.896-0.604-1.943-0.604-3.101c0-1.839,0.459-3.298,1.361-4.335c0.91-1.05,2.171-1.583,3.75-1.583
			c1.575,0,2.829,0.54,3.732,1.605c0.891,1.049,1.341,2.498,1.341,4.309c0,1.84-0.457,3.3-1.357,4.344
			C126.099,23.641,124.83,24.177,123.237,24.177z M123.294,12.847c-1.422,0-2.55,0.472-3.357,1.4
			c-0.817,0.939-1.23,2.283-1.23,3.992c0,1.085,0.188,2.057,0.558,2.887c0.366,0.807,0.896,1.44,1.581,1.876
			c0.688,0.434,1.494,0.65,2.392,0.65c1.455,0,2.557-0.461,3.376-1.411c0.816-0.947,1.23-2.297,1.23-4.004
			c0-1.683-0.408-3.019-1.216-3.971C125.816,13.312,124.728,12.847,123.294,12.847z M123.273,23.271
			c-1.282,0-2.294-0.457-3.003-1.36c-0.693-0.885-1.045-2.118-1.045-3.673c0-1.564,0.349-2.798,1.033-3.67
			c0.704-0.89,1.711-1.341,2.993-1.341c1.285,0,2.295,0.452,3.011,1.343c0.701,0.872,1.059,2.106,1.059,3.668
			c0,1.555-0.353,2.792-1.046,3.673C125.57,22.812,124.561,23.271,123.273,23.271z M123.254,13.749
			c-1.132,0-1.978,0.373-2.584,1.142c-0.61,0.777-0.921,1.903-0.921,3.347c0,1.433,0.314,2.561,0.932,3.349
			c0.615,0.779,1.464,1.158,2.593,1.158c1.134,0,1.982-0.379,2.592-1.158c0.619-0.785,0.933-1.915,0.933-3.349
			c0-1.44-0.315-2.565-0.94-3.342C125.238,14.123,124.386,13.749,123.254,13.749z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={textColor}
            d="M21.96,20.87c0,0.977-0.372,1.73-1.114,2.255c-0.742,0.524-1.798,0.79-3.165,0.79
			c-1.46,0-2.62-0.228-3.476-0.673v-1.077c1.099,0.55,2.257,0.824,3.476,0.824c1.078,0,1.897-0.181,2.456-0.538
			c0.56-0.359,0.838-0.832,0.838-1.43c0-0.547-0.222-1.015-0.668-1.386c-0.446-0.376-1.177-0.747-2.194-1.107
			c-1.093-0.393-1.859-0.734-2.301-1.017c-0.441-0.283-0.774-0.605-0.999-0.966c-0.227-0.359-0.339-0.796-0.339-1.314
			c0-0.816,0.345-1.463,1.029-1.938c0.687-0.474,1.646-0.714,2.879-0.714c1.177,0,2.294,0.221,3.355,0.663l-0.372,0.906
			c-1.072-0.44-2.066-0.662-2.982-0.662c-0.892,0-1.599,0.146-2.12,0.44c-0.522,0.295-0.785,0.704-0.785,1.224
			c0,0.57,0.202,1.023,0.608,1.369c0.405,0.341,1.197,0.72,2.375,1.145c0.985,0.354,1.703,0.672,2.15,0.96
			c0.451,0.283,0.785,0.607,1.009,0.968C21.848,19.956,21.96,20.38,21.96,20.87z"
          />
          <path
            fill={textColor}
            d="M17.68,24.177c-1.492,0-2.701-0.236-3.597-0.704l-0.14-0.071v-1.657l0.378,0.187
			c1.055,0.528,2.186,0.796,3.357,0.796c1.021,0,1.801-0.167,2.315-0.497c0.482-0.309,0.718-0.705,0.718-1.208
			c0-0.477-0.188-0.864-0.575-1.186c-0.423-0.355-1.134-0.713-2.115-1.059c-1.101-0.398-1.893-0.75-2.355-1.045
			c-0.473-0.305-0.836-0.657-1.08-1.048c-0.25-0.399-0.376-0.889-0.376-1.453c0-0.902,0.384-1.628,1.141-2.154
			c0.731-0.504,1.749-0.76,3.028-0.76c1.207,0,2.37,0.23,3.456,0.684l0.24,0.1l-0.57,1.39l-0.241-0.101
			c-2.031-0.836-3.898-0.788-4.874-0.235c-0.439,0.248-0.652,0.572-0.652,0.996c0,0.492,0.168,0.874,0.515,1.169
			c0.373,0.314,1.146,0.684,2.296,1.098c1.016,0.368,1.737,0.689,2.201,0.985c0.478,0.303,0.845,0.658,1.092,1.053
			c0.25,0.399,0.375,0.875,0.375,1.415c0,1.062-0.412,1.894-1.225,2.468C20.211,23.894,19.095,24.177,17.68,24.177z M14.465,23.082
			c0.806,0.379,1.886,0.571,3.213,0.571c1.305,0,2.32-0.25,3.014-0.743c0.676-0.475,1.004-1.143,1.004-2.04
			c0-0.444-0.097-0.815-0.298-1.138c-0.204-0.328-0.515-0.627-0.928-0.89c-0.425-0.27-1.132-0.585-2.099-0.932
			c-1.224-0.44-2.027-0.83-2.455-1.19c-0.465-0.394-0.701-0.921-0.701-1.569c0-0.621,0.309-1.109,0.917-1.452
			c1.078-0.61,3-0.694,5.087,0.111l0.173-0.421c-0.953-0.364-1.964-0.547-3.012-0.547c-1.17,0-2.089,0.226-2.73,0.667
			c-0.618,0.428-0.917,0.991-0.917,1.723c0,0.464,0.1,0.86,0.298,1.175c0.204,0.327,0.512,0.624,0.918,0.886
			c0.429,0.274,1.184,0.608,2.248,0.991c1.038,0.368,1.804,0.757,2.275,1.153c0.505,0.419,0.761,0.953,0.761,1.586
			c0,0.685-0.322,1.241-0.958,1.65c-0.6,0.385-1.474,0.579-2.597,0.579c-1.114,0-2.194-0.229-3.213-0.675v0.504H14.465z"
          />
        </g>
        <g>
          <path
            fill={textColor}
            d="M32.121,13.658h-2.57v10.057h-0.996V13.658h-2.028v-0.583l2.029-0.371v-0.846
			c0-1.339,0.246-2.32,0.736-2.947c0.494-0.627,1.301-0.94,2.417-0.94c0.604,0,1.207,0.089,1.808,0.271l-0.23,0.865
			c-0.536-0.166-1.068-0.25-1.598-0.25c-0.777,0-1.328,0.229-1.652,0.686c-0.325,0.46-0.487,1.205-0.487,2.236v1.015h2.571V13.658
			L32.121,13.658z"
          />
          <path
            fill={textColor}
            d="M29.812,23.978h-1.518V13.918h-2.03v-1.062l2.03-0.371v-0.627c0-1.412,0.26-2.43,0.792-3.108
			C29.63,8.06,30.511,7.71,31.708,7.71c0.629,0,1.264,0.095,1.883,0.282l0.244,0.074l-0.368,1.373l-0.26-0.081
			c-0.507-0.159-1.018-0.239-1.521-0.239c-0.693,0-1.163,0.188-1.439,0.576c-0.29,0.413-0.438,1.113-0.438,2.084v0.752h2.572v1.386
			h-2.57V23.978z M28.816,23.453h0.472V13.396h2.569v-0.339h-2.571V11.78c0-1.095,0.175-1.876,0.533-2.386
			c0.377-0.529,1.004-0.796,1.867-0.796c0.469,0,0.943,0.062,1.415,0.187l0.096-0.357c-0.494-0.127-0.994-0.193-1.489-0.193
			c-1.028,0-1.772,0.283-2.211,0.841c-0.452,0.574-0.681,1.511-0.681,2.785v1.063l-2.029,0.372v0.103h2.029L28.816,23.453
			L28.816,23.453z"
          />
        </g>
        <g>
          <path
            fill={textColor}
            d="M38.385,12.794v7.07c0,1.1,0.232,1.899,0.693,2.396c0.462,0.499,1.18,0.749,2.151,0.749
			c1.298,0,2.255-0.328,2.868-0.981c0.613-0.658,0.917-1.723,0.917-3.205v-6.028h0.986v10.917h-0.844l-0.18-1.507h-0.062
			c-0.711,1.14-1.971,1.709-3.787,1.709c-2.485,0-3.729-1.33-3.729-3.99v-7.129L38.385,12.794L38.385,12.794z"
          />
          <path
            fill={textColor}
            d="M41.129,24.177c-2.647,0-3.99-1.432-3.99-4.252v-7.392h1.507v7.332c0,1.025,0.209,1.773,0.623,2.218
			c0.41,0.44,1.068,0.666,1.959,0.666c1.218,0,2.117-0.302,2.678-0.899c0.562-0.604,0.848-1.623,0.848-3.027v-6.289h1.509v11.442
			h-1.338l-0.139-1.157C43.993,23.717,42.765,24.177,41.129,24.177z M37.663,13.056v6.869c0,2.507,1.133,3.728,3.467,3.728
			c1.731,0,2.899-0.518,3.564-1.585l0.077-0.122h0.377l0.088,0.228l0.153,1.276h0.35V13.057h-0.462v5.766
			c0,1.559-0.323,2.667-0.988,3.381c-0.663,0.707-1.693,1.066-3.06,1.066c-1.044,0-1.832-0.279-2.343-0.831
			c-0.505-0.546-0.763-1.412-0.763-2.575v-6.809L37.663,13.056L37.663,13.056z"
          />
        </g>
      </g>
    </svg>
  );
}
