import { z } from "zod";

export const artworkDto = z.object({
  id: z.string(),
  name: z.string(),
  dateOnSale: z.string(),
  status: z.string(),
  price: z.number(),
  imgURL: z.string(),
});

export const salesArtistDto = z.object({
  artistId: z.string(),
  salesStatusArtist: z.number(),
  artworks: z.array(artworkDto),
});
