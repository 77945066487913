import { queryOptions as tsqQueryOptions } from "@tanstack/react-query";
import { queryClient } from "shared/lib/react-query";
import { DetailArtwork } from "./detail-artwork.types";
import { DetailArtworkQuery } from "./detail-artwork.api";

const keys = {
  root: () => ["detailArtwork"],
  detailArtworkById: (artwork_uuid: string) => [
    ...keys.root(),
    "byId",
    artwork_uuid,
  ],
  infinityQuery: () => [...keys.root(), "infinityQuery"],
};

export const DetailArtworkService = {
  queryKey: (artwork_uuid: string) => keys.detailArtworkById(artwork_uuid),

  getCache: (artwork_uuid: string) =>
    queryClient.getQueryData<DetailArtwork>(
      DetailArtworkService.queryKey(artwork_uuid),
    ),

  setCache: (detailArtworkById: DetailArtwork, artwork_uuid: string) => {
    queryClient.setQueryData(
      DetailArtworkService.queryKey(artwork_uuid),
      detailArtworkById,
    );
  },

  removeCache: (artwork_uuid: string) =>
    queryClient.removeQueries({
      queryKey: DetailArtworkService.queryKey(artwork_uuid),
    }),

  queryOptions: (artwork_uuid: string) => {
    const chartKey = DetailArtworkService.queryKey(artwork_uuid);
    return tsqQueryOptions({
      queryKey: chartKey,
      queryFn: async ({ signal }) => {
        const detailArtworkById = await DetailArtworkQuery(
          { artwork_uuid },
          signal,
        );
        DetailArtworkService.setCache(detailArtworkById, artwork_uuid);

        return detailArtworkById;
      },
      initialData: () => DetailArtworkService.getCache(artwork_uuid)!,
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(chartKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (artwork_uuid: string) =>
    queryClient.prefetchQuery(DetailArtworkService.queryOptions(artwork_uuid)),

  ensureQueryData: async (artwork_uuid: string) =>
    queryClient.ensureQueryData(
      DetailArtworkService.queryOptions(artwork_uuid),
    ),
};
