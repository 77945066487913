import { Pagination } from "widgets/pagination";
import { tabDataArtworks } from "shared/lib/mocks/temp.dataTableArtworks.mock";
import { DataTableArtworks } from "widgets/data-table-artworks";
import DateSelect from "shared/lib/mosaic/components/DateSelect";
import SearchForm from "shared/lib/mosaic/partials/actions/SearchForm";
import FilterButton from "shared/lib/mosaic/components/DropdownFilter";

export function ArtworksPage() {
  // TODO implémenter la pagination
  if (tabDataArtworks.length > 10) {
    tabDataArtworks.splice(10, tabDataArtworks.length);
  }

  return (
    <div className="flex h-[100dvh] w-[100dvw] overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <div className="px-4 sm:px-6 lg:px-8 flex flex-col py-8 w-full max-w-9xl mx-auto h-[100dvh]">
          <div>
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  Artworks
                </h1>
              </div>
              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchForm placeholder="Search by invoice ID…" />
              </div>
            </div>

            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">
                <ul className="flex flex-wrap -m-1">
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">
                      All <span className="ml-1 text-indigo-200">67</span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                      Paid
                      <span className="ml-1 text-slate-400 dark:text-slate-500">
                        14
                      </span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                      Due
                      <span className="ml-1 text-slate-400 dark:text-slate-500">
                        34
                      </span>
                    </button>
                  </li>
                  <li className="m-1">
                    <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                      Overdue
                      <span className="ml-1 text-slate-400 dark:text-slate-500">
                        19
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Dropdown */}
                <DateSelect />
                {/* Filter button */}
                <FilterButton align="right" />
              </div>
            </div>
          </div>

          <div className="w-full max-w-9xl h-full mx-auto">
            <DataTableArtworks tabAllArtworks={tabDataArtworks} />
            <div className="mt-8">
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
