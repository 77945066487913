import { z } from "zod";

export const DataReportsChartsSchema = z.object({
  id: z.string(),
  name: z.string(),
  value: z.number(),
  country_iso_code: z.string().optional(),
  country: z.string().optional(),
});

export const ReportsChartsSchema = z.object({
  labels: z.array(z.string()),
  data: z.array(DataReportsChartsSchema),
  divisor: z.number().optional(),
});
