import { zodContract } from "shared/lib/zod";
import { createJsonQuery } from "shared/lib/fetch";
import { baseUrl } from "shared/api/realworld";
import { ReportsChartsContracts } from "shared/api/reports-charts";

export async function CountryReportsQuery(
  params: { filter: string },
  signal?: AbortSignal,
) {
  return createJsonQuery({
    request: {
      url: baseUrl(
        `${process.env.REACT_APP_API_URL_VERSION}/sfumato/home_table_top_country/`,
      ),
      method: "GET",
      query: {
        filter: params.filter,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    },
    response: {
      contract: zodContract(ReportsChartsContracts.ReportsChartsSchema),
    },
    abort: signal,
  });
}
