import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { PrivacyCookiesPage } from "./privacy-cookies.ui";

export const privacyCookiesRoute: RouteObject = {
  path: pathKeys.privacyCookies(),
  element: createElement(PrivacyCookiesPage),
  loader: async (args) => {
    return args;
  },
};
