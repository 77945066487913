import { createElement } from "react";
import { RouteObject } from "react-router-dom";
import { pathKeys } from "shared/lib/react-router";
import { ArtworkPage } from "./artwork-page.ui";

export const artworkPageRoute: RouteObject = {
  path: pathKeys.artwork(),
  element: createElement(ArtworkPage),
  loader: async (args) => {
    return args;
  },
};
