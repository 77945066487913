export function PrivacyCookiesPage() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 flex flex-col gap-10 py-8 w-full max-w-9xl mx-auto">
      <article className="flex flex-col gap-6 w-full mx-auto px-8 py-10">
        <h2 className="font-bold text-2xl">Privacy & cookies</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          ullamcorper convallis congue. Mauris placerat interdum est vitae
          blandit. Fusce sagittis ultricies rutrum. Aenean velit ante, bibendum
          a faucibus semper, pharetra at felis. In sed egestas mauris, vel
          maximus ex. Vestibulum ut porta arcu. Aliquam erat volutpat. Curabitur
          pulvinar imperdiet tincidunt. Integer quis neque et tortor sagittis
          volutpat eget non nunc. Fusce tincidunt sapien quam, eu facilisis
          ipsum porta vel. Aliquam erat volutpat.
        </p>
        <div>
          <h3>
            <strong>Lorem Ipsum</strong>
          </h3>
          <p>
            Mauris dapibus ante dolor, eu congue neque porta quis. Nulla at
            commodo felis. Nunc lacus eros, tincidunt nec ipsum sed, gravida
            porttitor quam. Pellentesque sodales nisi a orci mollis cursus. Duis
            blandit cursus placerat. Maecenas vestibulum justo et nisi
            tincidunt, vel venenatis augue ullamcorper. Praesent efficitur
            ultrices varius. Morbi dictum commodo arcu, ac dictum purus faucibus
            quis. Quisque mollis lobortis purus, nec bibendum urna rutrum eget.
            Sed suscipit lectus ac congue bibendum.
          </p>
        </div>
        <div>
          <p>
            Mauris dapibus ante dolor, eu congue neque porta quis. Nulla at
            commodo felis. Nunc lacus eros, tincidunt nec ipsum sed, gravida
            porttitor quam. Pellentesque sodales nisi a orci mollis cursus. Duis
            blandit cursus placerat. Maecenas vestibulum justo et nisi
            tincidunt, vel venenatis augue ullamcorper. Praesent efficitur
            ultrices varius. Morbi dictum commodo arcu, ac dictum purus faucibus
            quis. Quisque mollis lobortis purus, nec bibendum urna rutrum eget.
            Sed suscipit lectus ac congue bibendum.
          </p>
        </div>
        <div>
          <p>
            Aliquam vehicula cursus neque eget eleifend. Vestibulum vel neque et
            justo mollis egestas. Nulla id risus in dui mollis egestas. Sed
            malesuada erat eu sapien volutpat, eget pellentesque elit dapibus.
            Mauris finibus ante at ante mollis molestie. Fusce cursus vehicula
            gravida. Suspendisse auctor sed eros id tristique. Nunc orci tellus,
            vehicula a odio nec, varius suscipit tortor. Aliquam leo dui,
            tincidunt eu bibendum quis, vulputate a lacus. Donec sodales cursus
            purus sed viverra. Vestibulum ante ipsum primis in faucibus orci
            luctus et ultrices posuere cubilia curae; Maecenas congue mauris
            erat, non imperdiet sapien facilisis sed. Curabitur in tincidunt mi,
            sed ornare turpis. Nunc eros justo, aliquam ac commodo in, molestie
            in felis. In nisl erat, semper bibendum ligula ac, mattis rutrum
            ipsum. Pellentesque consectetur risus eget libero aliquam, ut congue
            lacus suscipit.
          </p>
        </div>
        <div>
          <p>
            Donec nunc ipsum, tincidunt at venenatis id, luctus nec orci.
            Pellentesque accumsan mollis urna a ultricies. Maecenas leo quam,
            scelerisque sit amet nulla ut, blandit luctus lectus. Nunc interdum
            dolor sit amet justo tincidunt, nec tincidunt diam rutrum. In hac
            habitasse platea dictumst. Nunc blandit, nunc ut varius condimentum,
            ex risus ultricies ligula, scelerisque placerat odio nulla sit amet
            turpis. Vivamus a facilisis leo. Aenean eleifend, dolor non porta
            suscipit, ligula elit condimentum elit, nec bibendum velit libero
            nec tortor. Nunc eu ultricies libero.
          </p>
        </div>
        <div>
          <p>
            Suspendisse aliquam enim dui, at maximus libero laoreet eget.
            Aliquam erat volutpat. Donec sed dui ac odio ullamcorper interdum.
            Phasellus nisl turpis, venenatis ac augue id, auctor varius erat.
            Sed at bibendum urna. Nulla ut mi quis sem aliquam fermentum vel
            vitae eros. Duis nec sem a justo commodo gravida suscipit sit amet
            arcu. Nunc et dapibus diam. Sed dapibus massa sit amet quam gravida,
            at tincidunt mi mollis. Maecenas vehicula magna eget felis congue
            volutpat. Etiam ligula nisi, interdum id placerat ac, rhoncus at
            metus. Etiam metus ex, consectetur in euismod eget, posuere et
            neque.
          </p>
        </div>
      </article>
    </div>
  );
}
