import { useQuery } from "@tanstack/react-query";
import {
  SimilarArtworksTypes,
  SimilarArtworksQueries,
} from "entities/similar-artworks";
import { useState, useEffect } from "react";
import { Loader } from "shared/ui/loader";

export function DataTableSimilarArtwork() {
  const params = new URLSearchParams(window.location.search);
  const artwork_uuid = params.get("artwork_uuid") as string;
  const [similarArtworks, setSimilarArtworks] =
    useState<SimilarArtworksTypes.SimilarArtworks | null>(null);

  const { queryKey: queryKeyArtwork, ...optionsSales } =
    SimilarArtworksQueries.SimilarArtworksService.queryOptions(artwork_uuid);
  const { data: queryArtwork, isLoading } = useQuery({
    queryKey: queryKeyArtwork,
    ...optionsSales,
  });

  useEffect(() => {
    setSimilarArtworks(queryArtwork);
  }, [artwork_uuid, queryArtwork]);

  const handleRedirect = (artworkId: string) => {
    window.location.href = `/artwork?artwork_uuid=${artworkId}`;
  };

  return (
    <div className="col-span-full relative overflow-scroll xl:col-span-8 h-full bg-white dark:bg-slate-800 shadow-lg rounded-lg border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 sticky top-0 border-b bg-white border-slate-100 dark:border-slate-700 flex flex-row justify-between">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          Similar Artworks
        </h2>
      </header>
      {isLoading ? (
        <div className="flex flex-col min-h-full items-center justify-center m-auto h-full">
          <Loader />
          <p>Loading data</p>
        </div>
      ) : (
        <div className="h-full max-h-full">
          <table className="table-auto w-full dark:text-slate-300">
            <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
              <tr>
                <th className="p-2 pl-5">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Artist</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Position</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Category</div>
                </th>
                <th className="p-2 pr-5">
                  <div className="font-semibold text-center">Price</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm font-medium divide-y divide-slate-100 dark:divide-slate-700">
              {similarArtworks?.data.map((sale, index) => (
                <tr
                  onClick={() => {
                    handleRedirect(sale.id);
                  }}
                  key={index}
                  className="hover:cursor-pointer hover:bg-slate-50 h-fit"
                >
                  <td className="p-2 pl-5">
                    <div className="flex flex-row items-center gap-2 w-max lg:w-fit">
                      <img
                        src={sale.imgURL}
                        className="w-10 h-10 rounded-full"
                        alt="actual artwork"
                      />
                      <div className="text-slate-800 dark:text-slate-100">
                        {sale.name}
                      </div>
                    </div>
                  </td>
                  <td className="p-2 w-fit">
                    <div className="text-left">{sale.nameArtist}</div>
                  </td>
                  <td className="p-2 w-fit">
                    <div className="text-center">
                      {sale.position} / {queryArtwork.totalArtworksCount}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">{sale.category}</div>
                  </td>
                  <td className="p-2 pr-5">
                    <div className="text-center">${sale.price}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
