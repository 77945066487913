export type TabArtworks = {
  id: number;
  photoUrl: string;
  name: string;
  website: string;
  location: string;
  position: number;
  worksOnline: number;
  salesVolume: number;
}[];

export const tabDataArtworks: TabArtworks = [
  {
    id: 1,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 1",
    website: "www.website.com",
    location: "Country",
    position: 1,
    worksOnline: 100,
    salesVolume: 10000,
  },
  {
    id: 2,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 2",
    website: "www.website.com",
    location: "Country",
    position: 2,
    worksOnline: 200,
    salesVolume: 20000,
  },
  {
    id: 3,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 3",
    website: "www.website.com",
    location: "Country",
    position: 3,
    worksOnline: 300,
    salesVolume: 30000,
  },
  {
    id: 4,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 4",
    website: "www.website.com",
    location: "Country",
    position: 4,
    worksOnline: 400,
    salesVolume: 40000,
  },
  {
    id: 5,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 5",
    website: "www.website.com",
    location: "Country",
    position: 5,
    worksOnline: 500,
    salesVolume: 50000,
  },
  {
    id: 6,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 6",
    website: "www.website.com",
    location: "Country",
    position: 6,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 7,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 7",
    website: "www.website.com",
    location: "Country",
    position: 7,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 8,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 8",
    website: "www.website.com",
    location: "Country",
    position: 8,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 9,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 9",
    website: "www.website.com",
    location: "Country",
    position: 9,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 10,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 10",
    website: "www.website.com",
    location: "Country",
    position: 10,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 11,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 11",
    website: "www.website.com",
    location: "Country",
    position: 11,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 12,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 12",
    website: "www.website.com",
    location: "Country",
    position: 12,
    worksOnline: 600,
    salesVolume: 60000,
  },
  {
    id: 13,
    photoUrl: "https://source.unsplash.com/random/100x100",
    name: "Name Artworks 13",
    website: "www.website.com",
    location: "Country",
    position: 13,
    worksOnline: 600,
    salesVolume: 60000,
  },
];
